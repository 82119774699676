<template>
  <div class="container topCon">
    <div class="ztTop"><span class="ztTitle">经营信息</span></div>
    <div class="wrapContent">
      <div class="data-hd">
        <p>经营信息</p>
      </div>
      <div class="data-db">
        <el-form ref="form" :model="form" label-width="132px" :rules="formRules" size="medium">
          <el-form-item label="商户简称" prop="merchantShortname">
            <el-input v-model="form.merchantShortname"></el-input>
            <div class="el-form-info">
              <p>1、在支付完成页向买家展示，需与微信经营类目相关。</p>
              <p> 2、简称要求： </p>
              <p> ① 不支持单纯以人名来命名，若为个体户经营，可用“个体户+经营者名称”或“经营者名称+业务”命名，如“个体户张三”或“张三餐饮店”；</p>
              <p> ② 不支持无实际意义的文案，如“XX特约商户”、“800”、“XX客服电话XXX”；</p>
            </div>
          </el-form-item>
          <el-form-item label="客服电话" prop="servicePhone">
            <el-input v-model="form.servicePhone"></el-input>
            <div class="el-form-info">
              <p>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务 </p>
              <p>2、请确保电话畅通，以便入驻后平台回拨确认。</p>
            </div>
          </el-form-item>
          <el-form-item label="经营场景" prop="salesInfo.salesScenesType">
            <el-checkbox-group v-model="form.salesInfo.salesScenesType" >
              <el-checkbox label="SALES_SCENES_STORE">线下场景</el-checkbox>
              <el-checkbox label="SALES_SCENES_MP">公众号</el-checkbox>
              <el-checkbox label="SALES_SCENES_MINI_PROGRAM">小程序</el-checkbox>
              <el-checkbox label="SALES_SCENES_WEB">互联网网站</el-checkbox>
              <el-checkbox label="SALES_SCENES_APP">APP</el-checkbox>
              <el-checkbox label="SALES_SCENES_WEWORK">企业微信</el-checkbox>
            </el-checkbox-group>
            <div class="el-form-info">
              <p>请勾选实际售卖商品/提供服务场景（至少一项），以便为你开通需要的支付权限 <a href="https://kf.qq.com/faq/170807nY7Jbi170807Yf6jIB.html">填写经营场景指引</a></p>
              <p>建议只勾选目前必须的场景，以便尽快通过入驻审核，其他支付权限你可在入驻后再根据实际需要发起申请</p>
            </div>
          </el-form-item>
<!--          线下场所-->
          <div v-if="form.salesInfo.salesScenesType.indexOf('SALES_SCENES_STORE')>-1">
            <div class="data-hd" >
              <p>线下场所<span class="desc_info">你选择了“线下场所”场景，入驻成功后，服务商可帮商户发起付款码支付、JSAPI支付，同时系统将会对地址等信息进行核实</span></p>
            </div>
            <el-form-item label="线下场所名称" prop="salesInfo.bizStoreInfo.bizStoreName">
              <el-input v-model="form.salesInfo.bizStoreInfo.bizStoreName"></el-input>
            </el-form-item>
            <el-form-item label="线下场所地址" prop="salesInfo.bizStoreInfo.bizStoreAddress">
              <el-input v-model="form.salesInfo.bizStoreInfo.bizStoreAddress"></el-input>
              <div class="el-form-info">
                <p>请填写详细的经营场所信息，如有多个场所，选择一个主要场所填写即可</p>
              </div>
            </el-form-item>
            <el-form-item label="线下场所门头照片"  prop="salesInfo.bizStoreInfo.storeEntrancePic">
              <el-upload
                  :auto-upload="true"
                  :action="uploadUrl"
                  :headers="upParams"
                  class="avatar-uploader"
                  :show-file-list="false"
                  :on-success="handleStoreEntrancePicSuccess"
              >
                <el-image v-if="storeEntrancePic" :src="storeEntrancePic" class="avatar" fit="contain"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="el-form-info">
                <p>请上传门店照片（要求门店招牌清晰可见）。若为停车场、售卖机等无固定门头照片的经营场所，请提供真实的经营现场照片</p>
              </div>
            </el-form-item>

            <el-form-item label="线下场所内部照片"  prop="salesInfo.bizStoreInfo.indoorPic">
              <el-upload
                  :auto-upload="true"
                  :action="uploadUrl"
                  :headers="upParams"
                  class="avatar-uploader"
                  :show-file-list="false"
                  :on-success="handleIndoorPicSuccess"
              >
                <el-image v-if="indoorPic" :src="indoorPic" class="avatar" fit="contain"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="el-form-info">
                <p>请上传门店内部环境照片。若为停车场、售卖机等无固定门头照片的经营场所，请提供真实的经营现场照片</p>
              </div>
            </el-form-item>
            <el-form-item label="线下场所对应的公众号APPID" prop="salesInfo.bizStoreInfo.bizSubAppid">
              <el-input v-model="form.salesInfo.bizStoreInfo.bizSubAppid"></el-input>
              <div class="el-form-info">
                <p>1、可填写已认证的公众号、小程序、应用的APPID，其中公众号APPID需是已认证的服务号、政府或媒体类型的订阅号。<a href="https://kf.qq.com/faq/181105JJNbmm181105eUZfee.html" target="_blank">AppID查看指引</a> </p>
                <p>2、完成进件后，系统发起特约商户号与该AppID的绑定（即配置为sub_appid，可在发起支付时传入） </p>
                <p> （1）若APPID主体与商家主体一致，则直接完成绑定； </p>
                <p> （2）若APPID主体与商家主体不一致，则商户签约时显示《联合营运承诺函》，并且AppID的管理员需登录<a href="https://mp.weixin.qq.com/" target="_blank">公众平台</a>确认绑定意愿；（暂不支持绑定异主体的应用APPID） </p>
              </div>
            </el-form-item>
          </div>
<!--          公众号-->
          <div  v-if="form.salesInfo.salesScenesType.indexOf('SALES_SCENES_MP')>-1">
            <div class="data-hd" >
              <p>公众号<span class="desc_info">你选择了“公众号”场景，审核通过后，服务商可帮商家发起JSAPI支付</span></p>
            </div>
            <el-form-item label="AppID所属" prop="appIdBelong">
              <el-radio v-model="appIdBelong" label="1">服务商公众号AppID</el-radio>
              <el-radio v-model="appIdBelong" label="2">商家公众号AppID</el-radio>
            </el-form-item>
            <el-form-item label="服务商公众号AppID" prop="salesInfo.mpInfo.mpAppid" v-if="appIdBelong == '1'">
              <el-input v-model="form.salesInfo.mpInfo.mpAppid"></el-input>
              <div class="el-form-info" >
                <p>可选择服务商商户号已绑定的APPID，需为已认证的服务号、政府或媒体类型的订阅号。<a href=" https://kf.qq.com/faq/181105JJNbmm181105eUZfee.html" target="_blank">AppID查看指引</a></p>
              </div>
            </el-form-item>
            <el-form-item label="商家公众号AppID" prop="salesInfo.mpInfo.mpSubAppid" v-if="appIdBelong == '2'">
              <el-input v-model="form.salesInfo.mpInfo.mpSubAppid"></el-input>
              <div class="el-form-info">
                <p>1、请填写已认证的公众号APPID，需是已认证的服务号、政府或媒体类型的订阅号；<a href="https://kf.qq.com/faq/181105JJNbmm181105eUZfee.html" target="_blank">AppID查看指引</a></p>
                <p>2、完成进件后，系统发起特约商户号与该AppID的绑定（即配置为sub_appid，可在发起支付时传入）</p>
                <p> （1）若APPID主体与商家主体一致，则直接完成绑定；</p>
                <p> （2）若APPID主体与商家主体不一致，则商户签约时显示《联合营运承诺函》，并且AppID的管理员需登录<a href="https://mp.weixin.qq.com/" target="_blank">公众平台</a>确认绑定意愿； </p>
              </div>
            </el-form-item>
            <el-form-item label="公众号页面截图（选填）" >
              <el-upload
                  :auto-upload="true"
                  :action="uploadUrl"
                  :headers="upParams"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :on-success="handleimgsUrl"
                  :file-list.sync="indoorPicFileList"
                  accept="image/jpeg,image/png"
                  :limit="5"
                  ref="upload"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>
          </div>
<!--          小程序-->
          <div v-if="form.salesInfo.salesScenesType.indexOf('SALES_SCENES_MINI_PROGRAM')>-1">
            <div class="data-hd" >
              <p>小程序<span class="desc_info">你选择了“小程序”场景，审核通过后，服务商可帮商家发起JSAPI支付</span></p>
            </div>
            <el-form-item label="小程序APPID" prop="salesInfo.miniProgramInfo.miniProgramAppid" >
              <el-input v-model="form.salesInfo.miniProgramInfo.miniProgramAppid"></el-input>
              <div class="el-form-info">
                <p>1、请填写已认证的小程序APPID。<a href="https://kf.qq.com/faq/181105JJNbmm181105eUZfee.html" target="_blank">AppID查看指引</a>
                  <el-popover
                      placement="bottom"
                      width="200"
                      trigger="manual"
                      content="wx71e5aa098fae6c5d"
                      v-model="visible">
                    <el-button slot="reference" style="margin-left: 20px" @click="visible = !visible">点击获取APPID</el-button>
                  </el-popover>
                  </p>
                <p>2、完成进件后，系统发起特约商户号与该AppID的绑定（即配置为sub_appid，可在发起支付时传入）</p>
                <p> （1）若APPID主体与商家主体一致，则直接完成绑定；</p>
                <p> （2）若APPID主体与商家主体不一致，则商户签约时显示《联合营运承诺函》，并且AppID的管理员需登录<a href="https://mp.weixin.qq.com/" target="_blank">公众平台</a>确认绑定意愿； </p>
              </div>
            </el-form-item>
            <el-form-item label="小程序截图" >
              <el-upload
                  :auto-upload="true"
                  :action="uploadUrl"
                  :headers="upParams"
                  :class="{
                  disabled: uploadDisabled,
                }"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleMiniRemove"
                  :on-success="handleMiniImgsUrl"
                  :file-list.sync="miniPicFileList"
                  accept="image/jpeg,image/png"
                  :limit="5"
                  ref="upload"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
<!--              <el-upload-->
<!--                  :auto-upload="true"-->
<!--                  :action="uploadUrl"-->
<!--                  :headers="upParams"-->
<!--                  class="avatar-uploader"-->
<!--                  :show-file-list="false"-->
<!--                  :on-success="handleMiniSuccess"-->
<!--              >-->
<!--                <el-image v-if="miniProgramPics" :src="miniProgramPics" class="avatar" fit="contain"/>-->
<!--                <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--              </el-upload>-->
            </el-form-item>
          </div>
<!--          APP-->
          <div v-if="form.salesInfo.salesScenesType.indexOf('SALES_SCENES_APP')>-1">
            <div class="data-hd" >
              <p>APP<span class="desc_info">你选择了“APP”场景，审核通过后，服务商可帮商家发起APP支付</span></p>
            </div>
            <el-form-item label="AppID所属" prop="appIdBelong">
              <el-radio v-model="appIdAPPBelong" label="1">服务商应用AppID</el-radio>
              <el-radio v-model="appIdAPPBelong" label="2">商家应用AppID</el-radio>
            </el-form-item>
            <el-form-item label="服务商应用AppID" prop="salesInfo.appInfo.appAppid" v-if="appIdAPPBelong == '1'">
              <el-input v-model="form.salesInfo.appInfo.appAppid"></el-input>
              <div class="el-form-info" >
                <p>可填写当前服务商商户号已绑定的应用AppID；<a href="https://kf.qq.com/faq/181105JJNbmm181105eUZfee.html" target="_blank">查看APPID指引</a></p>
              </div>
            </el-form-item>
            <el-form-item label="商家应用AppID" prop="salesInfo.mpInfo.appSubAppid" v-if="appIdAPPBelong == '2'">
              <el-input v-model="form.salesInfo.appInfo.appSubAppid"></el-input>
              <div class="el-form-info">
                <p> 请填写与商家主体一致且已认证的应用APPID；暂不支持绑定异主体的APPID</p>
                <p>审核通过后，系统将发起特约商家商户号与该AppID的绑定（即配置为sub_appid），服务商随后可在发起支付时选择传入该appid，以完成支付，并获取sub_openid用于数据统计，营销等业务场景 。</p>
                <p> （1）若APPID主体与商家主体一致，则直接完成绑定；<a href="https://kf.qq.com/faq/181105JJNbmm181105eUZfee.html" target="_blank">AppID查看指引</a></p>
              </div>
            </el-form-item>
            <el-form-item label="APP截图" >
              <el-upload
                  :auto-upload="true"
                  :action="uploadUrl"
                  :headers="upParams"
                  class="avatar-uploader"
                  :show-file-list="false"
                  :on-success="handleIndoorPicSuccess"
              >
                <el-image v-if="indoorPic" :src="indoorPic" class="avatar" :fit="contain"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>
<!--          互联网-->
          <div v-if="form.salesInfo.salesScenesType.indexOf('SALES_SCENES_WEB')>-1">
            <div class="data-hd" >
              <p>互联网网站<span class="desc_info">你选择了“互联网网站”场景，审核通过后，服务商可帮商家发起JSAPI支付、Native支付</span></p>
            </div>
            <el-form-item label="互联网网站域名" prop="salesInfo.webInfo.domain" >
              <el-input v-model="form.salesInfo.webInfo.domain"></el-input>
              <div class="el-form-info" >
                <p>如为PC端商城、智能终端等场景，可上传官网链接（格式需以http://或https://开头）；</p>
                <p>网站域名需ICP备案，若备案主体与申请主体不同，请上传加盖公章的<a href="https://gtimg.wechatpay.cn/mch/img/icp.doc" target="_blank">网站授权函</a></p>
              </div>
            </el-form-item>
            <el-form-item label="网站授权函" >
              <el-upload
                  :auto-upload="true"
                  :action="uploadUrl"
                  :headers="upParams"
                  class="avatar-uploader"
                  :show-file-list="false"
                  :on-success="handleWebAuthorisationSuccess"
              >
                <el-image v-if="webAuthorisation" :src="webAuthorisation" class="avatar" :fit="contain"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div  class="el-form-info">
                <p>若备案主体与申请主体不同，请务必上传加盖公章的网站授权函</p>
              </div>
            </el-form-item>
            <el-form-item label="互联网网站对应的商家AppID（选填)" prop="salesInfo.webInfo.webAppid" >
              <el-input v-model="form.salesInfo.webInfo.webAppid"></el-input>
              <div class="el-form-info" >
                <p>1、可填写已认证的公众号、小程序、应用的APPID，其中公众号APPID需是已认证的服务号、政府或媒体类型的订阅号。<a href="https://kf.qq.com/faq/181105JJNbmm181105eUZfee.html" target="_blank">AppID查看指引</a> </p>
                <p>2、完成进件后，系统发起特约商户号与该AppID的绑定（即配置为sub_appid，可在发起支付时传入）</p>
                <p>  （1）若APPID主体与商家主体一致，则直接完成绑定；</p>
                <p>  （2）若APPID主体与商家主体不一致，则商户签约时显示《联合营运承诺函》，并且AppID的管理员需登录<a href="https://mp.weixin.qq.com/" target="_blank">公众平台</a>确认绑定意愿；（暂不支持绑定异主体的应用APPID）</p>
              </div>
            </el-form-item>
          </div>
<!--          企业微信-->
          <div v-if="form.salesInfo.salesScenesType.indexOf('SALES_SCENES_WEWORK')>-1">
            <div class="data-hd" >
              <p>企业微信<span class="desc_info">你选择了“企业微信”场景，审核通过后，商家可自行发起JSAPI支付、Native支付</span></p>
            </div>
            <el-form-item label="商家企业微信CorpID" prop="salesInfo.weworkInfo.subCorpId" >
              <el-input v-model="form.salesInfo.weworkInfo.subCorpId"></el-input>
              <div class="el-form-info" >
                <p>可填写与商家主体一致且已认证的企业微信CorpID；审核通过后，系统将为商家开通企业微信专区的自有交易权限，并完成商家商户号与该CorpID的绑定；<a href="https://kf.qq.com/faq/181105JJNbmm181105eUZfee.html" target="_blank">CorpID查看指引</a></p>
              </div>
            </el-form-item>
            <el-form-item label="企业微信页面截图" >
              <el-upload
                  :auto-upload="true"
                  :action="uploadUrl"
                  :headers="upParams"
                  class="avatar-uploader"
                  :show-file-list="false"
                  :on-success="handleWebAuthorisationSuccess"
              >
                <el-image v-if="webAuthorisation" :src="webAuthorisation" class="avatar" :fit="contain"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div  class="el-form-info">
                <p>若备案主体与申请主体不同，请务必上传加盖公章的网站授权函</p>
              </div>
            </el-form-item>
          </div>

        </el-form>
        <div class="bottomBtn">
          <el-button class="btn-defalut" @click="backToMerchantApply">返回</el-button>
          <el-button class="btn-defalut" @click="saveToDraft" v-if="isWrite == true && isSee == 'false'">保存草稿箱</el-button>
          <el-button class="success btn-defalut" @click="toNext" v-if="isWrite == true && isSee == 'false'">下一步</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {addBusinessInfo,getBusinessInfo} from "../../../api/wxMerchant";
import {baseImgUrl} from "../../../utils/constant";

export default {
  name: "managementInfo",
  components: {},
  data() {
    var validateIdCardNational = (rule, value, callback) => {
      if (!this.storeEntrancePic) {
        callback(new Error('请上传线下场所门头照片'))
      } else {
        callback()
      }
    }
    let token = localStorage.getItem("token")
    let info = JSON.parse(localStorage.getItem('info'));
    return {
      visible:false,
      isWrite:false,
      isSee:false,
      options:[
        {
          value: true,
          label: '是'
        }, {
          value: false,
          label: '否'
        }
      ],
      uploadDisabled:false,
      dialogImageUrl: '',
      miniProgramPics:'',
      indoorPicFileList:[],
      dialogVisible: false,
      subjectInfo:{},
      webAuthorisation:'',
      appIdBelong:'1',
      appIdAPPBelong:'1',
      form:{
        miniProgramSubAppid:'',
        salesInfo:{
          salesScenesType:[],
          bizStoreInfo:{},
          mpInfo:{},
          miniProgramInfo:{miniProgramSubAppid:''},
          appInfo:{},
          webInfo:{},
          weworkInfo:{}
        },//经营场景信息
      },
      merchantId:'',
      miniPicFileList:[],
      uploadUrl:this.$store.state.uploadingUrl,
      storeEntrancePic:'',
      indoorPic:'',
      ossUrl: this.$store.state.ossUrl,
      upParams: {
        token: token
      },
      formRules: {
        merchantShortname:[
          { required: true, message: '请填写商家简称', trigger: 'blur' },
        ],
        servicePhone:[
          { required: true, message: '请填写客服电话', trigger: 'blur' },
        ],
        'salesInfo.salesScenesType':[
          { type: 'array', required: true, message: '请至少选择一个场景', trigger: 'change' }
        ],
        'salesInfo.bizStoreInfo.bizStoreName':[
          { required: true, message: '请填写门店名称', trigger: 'blur' },
        ],
        'salesInfo.bizStoreInfo.bizStoreAddress':[
          { required: true, message: '请填写门店详细地址', trigger: 'blur' },
        ],
        'salesInfo.bizStoreInfo.storeEntrancePic':[
          { validator:validateIdCardNational, trigger: 'blur' },
        ],
        'salesInfo.miniProgramInfo.miniProgramAppid':[
          { required: true, message: '请填写商家小程序AppID', trigger: 'blur' },
        ],
        'salesInfo.appInfo.appAppid':[
          { required: true, message: '请填服务商应用AppID', trigger: 'blur' },
        ],
        'salesInfo.mpInfo.appSubAppid':[
          { required: true, message: '请填写商家应用AppID', trigger: 'blur' },
        ],
        'salesInfo.webInfo.domain':[
          { required: true, message: '请填写互联网网站域名', trigger: 'blur' },
        ],
        'salesInfo.weworkInfo.subCorpId':[
          { required: true, message: '请填写商家企业微信CorpID', trigger: 'blur' },
        ]
      }
    };

  },
  computed: {},
  filters:{

  },
  watch: {},
  mounted() {
    this.merchantId = this.$route.query.merchantId

  },
  created() {
    if (this.$router.history.current.query.isSourceAdmin) {
      localStorage.setItem('isSourceAdmin', this.$router.history.current.query.isSourceAdmin);
    }
    this.merchantId = this.$route.query.merchantId
    this.isSee = this.$route.query.isSee
    this.isWrite = this.$route.query.isWrite
    if (this.isWrite == 'true'){
      this.isWrite = true
    }else {
      if (this.isSee == 'false'){
        this.$message({
          message: '请先填写上一模块信息',
          type: 'warning',
          duration:2000
        });
      }

    }

    this.getBusinessInfo()
  },
  methods: {
    getBusinessInfo(){
      getBusinessInfo({merchantId:this.merchantId}).then(response=>{
        if (response.code == 200 && response.data.salesInfo){
          this.form = response.data
          this.form.salesInfo.salesScenesType =  this.form.salesInfo.salesScenesTypeList
          if (!this.form.salesInfo.miniProgramInfo){
            this.form.salesInfo.miniProgramInfo = {}
          }else {
            // 小程序截图回显
            if (this.form.salesInfo.miniProgramInfo.miniProgramPicsList){
              // let arr = []
              // this.form.salesInfo.miniProgramInfo.miniProgramPicsList.forEach(item=>{
              //   arr.push(this.ossUrl + item)
              // })
              // this.miniPicFileList = arr
              this.miniPicFileList = this.form.salesInfo.miniProgramInfo.miniProgramPicsList.map(item => {
                return {
                  name: '',
                  url: baseImgUrl + item,
                }
              })
            }
          }
          if (!this.form.salesInfo.bizStoreInfo){
            this.form.salesInfo.bizStoreInfo = {}
          }else {
            // 公众号页面截图回显
            this.indoorPicFileList = this.form.salesInfo.bizStoreInfo.storeEntrancePicList.map(item => {
              return {
                  name: '',
                  url: baseImgUrl + item,
              }
            })
          }
          if (!this.form.salesInfo.mpInfo){
            this.form.salesInfo.mpInfo = {}
          }
          if (!this.form.salesInfo.webInfo){
            this.form.salesInfo.webInfo = {}
          }
          if (!this.form.salesInfo.weworkInfo){
            this.form.salesInfo.weworkInfo = {}
          }
          if (!this.form.salesInfo.appInfo){
            this.form.salesInfo.appInfo = {}
          }
        }

      })
    },
    handlePictureCardPreview(file){
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 公众号页面截图， 删除回调
    handleRemove(file, fileList) {
      this.indoorPicFileList = fileList
      let reg = new RegExp(baseImgUrl, "g")
      this.form.salesInfo.bizStoreInfo.storeEntrancePicList = this.indoorPicFileList.map(item => item.url.replace(reg, ''))
    },
    // 小程序截图，删除回调
    handleMiniRemove(file, fileList) {
      this.miniPicFileList = fileList
      let reg = new RegExp(baseImgUrl, "g")
      this.form.salesInfo.miniProgramInfo.miniProgramPicsList = this.miniPicFileList.map(item => item.url.replace(reg, ''))
    },
    //小程序截图 上传回调
    handleMiniImgsUrl(response, file, fileList) {
      if (response.code === 200) {
        this.$message({
          message: '上传成功',
          type: 'success'
        });
        // this.miniPicFileList.push(response.data)
        // this.form.salesInfo.miniProgramInfo.miniProgramPicsList = this.miniPicFileList
       this.miniPicFileList = this.miniPicFileList.push({
         name: file.name,
         url: baseImgUrl + response.data,
       })
       let reg = new RegExp(baseImgUrl, "g")
       this.form.salesInfo.miniProgramInfo.miniProgramPicsList = this.miniPicFileList.map(item => item.url.replace(reg, ''))
      
      }else {
        this.$message({
          message: response.message,
          type: 'error'
        });
        fileList.pop()
        this.miniPicFileList = fileList
        // this.imgsFileList = fileList.pop()
      }},
    // 公众号页面截图 上传回调
    handleimgsUrl(response, file, fileList) {
      if (response.code === 200) {
        this.$message({
          message: '上传成功',
          type: 'success'
        });
        // this.indoorPicFileList.push(response.data)
        // this.form.salesInfo.bizStoreInfo.storeEntrancePicList = this.indoorPicFileList
        this.indoorPicFileList = this.indoorPicFileList.push({
          name: file.name,
          url: baseImgUrl + response.dat
        })
        let reg = new RegExp(baseImgUrl, "g")
        this.form.salesInfo.bizStoreInfo.storeEntrancePicList = this.indoorPicFileList.map(item => item.url.replace(reg, ''))
      }else {
        this.$message({
          message: response.message,
          type: 'error'
        });
        fileList.pop()
        this.indoorPicFileList = fileList
        // this.imgsFileList = fileList.pop()
      }
    },
    handleWebAuthorisationSuccess(res,file){
      this.form.salesInfo.webInfo.webAuthorisation = res.data
      this.webAuthorisation = this.ossUrl +  this.form.salesInfo.webInfo.webAuthorisation
    },
    handleStoreEntrancePicSuccess(res,file){
      this.form.salesInfo.bizStoreInfo.storeEntrancePic = res.data
      this.storeEntrancePic = this.ossUrl + this.form.salesInfo.bizStoreInfo.storeEntrancePic
    },
    handleIndoorPicSuccess(res,file){
      this.form.salesInfo.bizStoreInfo.indoorPic = res.data
      this.indoorPic = this.ossUrl + this.form.salesInfo.bizStoreInfo.indoorPic
    },
    handleMiniSuccess(res,file){
      this.form.salesInfo.miniProgramInfo.miniProgramPics = res.data
      this.miniProgramPics = this.ossUrl + this.form.salesInfo.miniProgramInfo.miniProgramPics
    },

    toNext(){
      //跳转下个页面且将数据进行保存
      this.$refs.form.validate(async (valid) => {
        if (valid) {
         this.form.merchantId = this.merchantId
          if (this.form.cardTime == '长期'){
            this.form.identityInfo.idCardInfo.cardPeriodEnd = '长期'
          }
        if (this.term == '长期'){
          this.form.businessLicenseInfo.periodEnd = '长期'
        }
        this.form.isSubmit = true
          addBusinessInfo(this.form).then(res => {
            if (res.code === 200) {
              this.$router.push({path:'/settlementInfo',query:{'merchantId':this.merchantId,isWrite:true,isSee:this.isSee}})
              this.$message({
                message: '操作成功',
                type: 'success'
              });
            }
          })
        }
      })
    },
    saveToDraft(){
      this.form.merchantId = this.merchantId
      addBusinessInfo(this.form).then(res => {
        if (res.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }
      })
    },
    backToMerchantApply(){
      this.$message.closeAll()
      let than = this
      setTimeout(function () {
        than.$router.push({path:'/merchantApplyInfo',query:{merchantId:than.merchantId,isSee:than.isSee}})
      },0)
    }
  }
};
</script>

<style scoped>
.ztTitle{
  font-size: 18px;
}
.topCon {
  position: relative;

}
.ztTop{
  margin-top: 20px;
  margin-bottom: 20px;
}
.wrapContent{
  padding: 32px 24px;
  margin-bottom: 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px -1px rgb(0 0 0 / 8%);
}
.data-hd{
  margin-bottom: 30px;
  height: 14px;
  line-height: 1;
  padding-left: 10px;
  border-left: 3px solid #00c250;
}
.desc_info{
  font-size: 12px;
  color: #999;
  margin-left: 15px;
}
.descWord{
  color: #999;
  margin-top: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 106px;
  line-height: 106px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/.el-upload--text {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 193px;
  height: 109px;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon[data-v-e66b3640] {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 106px;
  line-height: 106px;
  text-align: center;
}
.el-input {
  position: relative;
  display: inline-block;
  width: 27%;
}
/*/deep/ .el-input__inner{*/
/*  height: 50px;*/
/*}*/
/*/deep/.el-input__inner {*/
/*  !* height: 34px !important; *!*/
/*  height: 47px!important;*/
/*  line-height: 47px !important;*/
/*  font-size: 14px !important;*/
/*}*/
.el-form-item{
  margin-bottom: 25px;
}

.btn-defalut{
  color: #333;
  background-color: #eee;
  border-color: #eee;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  padding: 12px 40px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 30px;
}
.btn-defalut:hover{
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #333;
}

.success{
  color: #fff;
  background-color: #00c250;
  border-color: #00c250;
}
.success:hover {
  background: #33ce73;
  border-color: #33ce73;
  color: #fff;
}
.avatar {
width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.el-form-info{
  color: #999;
  font-size: 14px;
  line-height: 1.6;
  padding-top: 8px;
}
</style>
